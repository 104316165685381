import React, {Fragment, useContext } from 'react'

import Header from './Layout/Header/Header'
import Service from './Layout/Service/Service'
import Carosello from './Layout/Carosello/Carosello'
import Overview from './Layout/Overview/Overview'
import Faq from './Layout/Faq/Faq'

import WindowSizeContext from '../../../Context/WindowSizeContext'

const LandingPage = props => 
{
  const size = useContext(WindowSizeContext);
  return(
        <Fragment>
           <Header/>
           <main className="home-main">
               <Service/>
               {(size.width >= 1000) && <Overview/>}
               <Carosello/>
               <Faq/>
           </main>
        </Fragment>
    );
}

export default LandingPage;