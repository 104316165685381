import React from 'react'
import scrollDown from '../../../../Functions/scrollDown'

import overlay from '../../../../../Assets/Images/phone_overlay.png'

import cerca_persone from '../../../../../Assets/Video/cerca_persone.MP4'
import cerca_progetti from '../../../../../Assets/Video/cerca_progetti.MP4'
import news from '../../../../../Assets/Video/news.MP4'
import progetti from '../../../../../Assets/Video/progetti.MP4'

import cerca_persone2 from '../../../../../Assets/Video/cerca_persone.webm'
import cerca_progetti2 from '../../../../../Assets/Video/cerca_progetti.webm'
import news2 from '../../../../../Assets/Video/news.webm'
import progetti2 from '../../../../../Assets/Video/progetti.webm'

import rocket from '../../../../../Assets/Icon/rocket.png'

import CardList from '../../../../Cards/PhoneCard/PhoneCardList'

const Overview = props =>{
    const overviewCards = [{title:"CREA IL TUO TEAM", one:"Sfoglia profili e cerca persone per creare il tuo team", two: "Seleziona i settori e le competenze che stai cercando nei i membri del tuo futuro team", three:"Organizza un primo incontro per conoscervi e vedere se volete viaggiare insieme",  mp4: cerca_persone, webm: cerca_persone2, id:"search_person"},
    {title:"SCOPRI NUOVI PROGETTI", one:"Sfoglia i progetti per trovare la tua prossima avventura", two:"Cerca progetti e team nei quali cercano persone con le tue competenze", three:"Organizza un primo incontro per vedere se è il progetto giusto per te (e vuoi condividere la loro destinazione)", mp4: cerca_progetti, webm: cerca_progetti2, id:"search_project"},
    {title:"GESTISCI I TUOI PROGETTI", one:"Questa è la vostra sala di comando: gestisci il tuo progetto con il tuo team al completo!", two: "Dividetevi i compiti all’interno del team e rimanete facilmente in contatto tra di voi", three: "Pieno controllo grazie alla visione d’insieme di tutti le diverse aree di lavoro", mp4: progetti, webm: progetti2, id:"project_managment"},
    {title:"CONTINUA A IMPARARE CON GLI ARTICOLI", one:"Impara con gli articoli provenienti da diverse galassie, dalla programmazione, all’economia e marketing", two: "Trai vantaggio anche dalle conoscenze esclusive della community", three: "Condividi quello le tue competenze con la community e sviluppa il tuo profilo scrivendo i tuoi articoli", mp4: news, webm: news2, id:"article"}]
    
    return (
        <section className="section-overview u-margin-bottom-large">
            <div className="section-overview__list">
                <CardList cards={overviewCards} img={rocket} overlay={overlay}/>
            </div>
    
            <div className="button-group u-margin-top-large">
                <button
                className="button-round button-round--shadow button-round--big button-round--yellow" onClick={() => {scrollDown("service", -100);}}>Scopri altre funzionalità</button>
            </div>
        </section>
    );

}
export default Overview;
